<template>
	<div class='pt20 pb20 flex'>
		<div class="back_box flex row-center col-center text-center mr10" @click="$router.push(`/coreJiShu/${CategoryId}`)">返回<br>列表</div>

			<div v-html="skillCont" class="ql-editor flex1 pr40 fs11 color9D" style="margin: 0 auto;"></div>

	</div>
</template>

<script>
	import {getSkillDetails} from '@/api'
	export default {
		name:"coreJiShuD",
		data() {
			return {
				CategoryId:'',
				id: '',
				skillCont:''
			};
		},

		components: {},

		created() {
			this.CategoryId=this.$route.params.CategoryId
			this.id = this.$route.params.id
			this.getSkillDetails(this.id)
		},

		mounted() {},

		methods: {
			async getSkillDetails(id) {
				const res= await getSkillDetails({id})
				res.data.skillCont=res.data.skillCont.replace(/<img /g, '<img style="width:100%" ')
				this.skillCont=res.data.skillCont
			}
		}
	}
</script>
<style lang='scss' scoped>
	img {
		display: inline-block !important;
	}
	.back_box {
		position: sticky;
		top: 80px;
		width: 44px;
		height: 44px;
		background: #F7B500;
		border: 1px solid #F7B500;
		font-size: 11px;
		font-weight: 500;
		color: #595959;
	}
	.ql-editor {
		padding: 0;
	}
</style>
